.svg-menu-item {
    fill: #727C8A;
}

.svg-menu-item :hover {
    fill: #00C1D5;
}

.svg-menu-item.active {
    fill: #00C1D5;
}