@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import "~antd/dist/antd.less";

@font-family: "Poppins", sans-serif;
// vertical paddings
@padding-lg: 30px; // containers
@padding-md: 24px; // small containers and buttons
@padding-sm: 18px; // Form controls and items
@padding-xs: 12px; // small items
@padding-xss: 8px; // more small

// vertical margins
@margin-lg: 30px; // containers
@margin-md: 24px; // small containers and buttons
@margin-sm: 18px; // Form controls and items
@margin-xs: 12px; // small items
@margin-xss: 8px; // more small

// @font-size-base: 16px;

@border-radius-base: 5px;
@primary-color: #00c1d5;
@info-color: #20063b;
@success-color: #7ec820;
@warning-color: #ffb427;
@error-color: #f04646;
@height-base: 40px;
@height-lg: 42px;
@height-sm: 32px;
@menu-item-color: #727c8a;
@border-radius-base: 16px;
@text-color: #1b1b1b;
@heading-color: #1b1b1b;

.ant-input-affix-wrapper {
  .ant-input {
    border-radius: 2px;
  }
}

* {
  font-family: "Poppins", sans-serif;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: rgba(0, 0, 0, 0) !important;
  border-radius: 5px !important;
  overflow: hidden !important;
}

.react-tel-input .country-list {
  box-shadow: 1px 2px 10px rgb(0 0 0 / 4%) !important;
  border: 1px solid #d3d3d35e !important;
  border-radius: 8px !important;
}

.react-tel-input .country-list .search-box {
  width: calc(100% - 9px) !important;
  font-size: 13px !important;
  padding: 8px 12px !important;
  border: 1px solid #ededed !important;
  border-radius: 8px !important;
  margin: 0 !important;
  overflow: hidden !important;
}

.react-tel-input .country-list .search-emoji {
  display: none !important;
}

// Remove ant input number up down
.ant-input-number-handler-wrap {
  display: none !important;
}

.ant-input-number-input {
  padding-right: 12px !important;
}
.ant-select-item-option-content {
  .disabled {
    pointer-events: none;
    opacity: 0.8;
    cursor: not-allowed;
    color: #4dd4e2;
  }
}

.input-remark-wraper {
  .input-remark {
    width: 94%;
    margin-left: 10px;
    color: #03b4ce !important;
    background-color: transparent;
    border-radius: unset !important;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #4dd4e2;
    &:focus {
      border-top: unset !important;
      border-left: unset !important;
      border-right: unset !important;
      outline: none !important;
      box-shadow: none !important;
      border-bottom: 1px solid #4dd4e2;
    }
  }
}

.button-discard,
.button-discard:active,
.button-discard:focus,
.button-discard:hover {
  background-color: #828282;
  color: white;
  outline: none;
  border: none;
}

.text-popup-confirm {
  font-size: 14px;
  & > span {
    position: relative;
    top: -4px;
  }
}

.text-popup-confirm.text-popup-confirm-ok {
  border-color: #0098b0;
  color: #0098b0;
}

.tosp-discarded {
  pointer-events: none;
  position: relative;
  &:after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 10;
  }
  & .button-discard {
    background-color: #ffffff;
    border: 1px solid #828282;
    color: #828282;
  }
}

.ant-dropdown-menu-item {
  &.notify-new-read {
    background-color: #f3f8fc;
  }
}

.show-more-notifications {
  background: #55bed2;
  color: #fff;
  padding-bottom: 0;
}

.custom-bg-disabled[disabled] {
  background: #c6c3c366;
}

.inputTitleEmail {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border: none;
  outline: none;
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.disable-bg-black.ant-input-disabled {
  color: #101820 !important;
  background: #f5f5f5;
}
.disable-bg-black.ant-select-disabled .ant-select-selector {
  color: #101820 !important;
  background: #f5f5f5 !important;
}
.disable-bg-black.ant-input-number-disabled {
  background: #f5f5f5;
}

.disable-bg-black.ant-input-number-disabled input {
  color: #101820 !important;
}
