.flat-table {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 25px;
  }
  .flat-table th, .flat-table td {
  }

  .flat-table th {
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    padding: 1em;
    color: rgba(0,0,0,0.45);
    text-shadow: 0 0 1px rgba(0,0,0,0.1);
    font-size: 1.5em;
  }
  .flat-table td {
    color: #f7f7f7;
    padding: 0.7em 1em 0.7em 1.15em;
    text-shadow: 0 0 1px rgba(255,255,255,0.1);
    font-size: 1.4em;
  }
  .flat-table tr {

  }
  .flat-table-1 {
    background: #f3d5df;
  }
  .flat-table-2 {
    background: #f06060;
  }
  .flat-table-3 {
    background: #52be7f;
  }
  .flat-table-3 tr:hover {
    background: rgba(0,0,0,0.1);
  }

.head-line {
    background: rgb(255, 255, 255);
    border-radius: 50%;
}

.th-name {
    border-top-left-radius: 20px;
}
.th-date {

}
.th-upload {
    border-top-right-radius: 20px;
}

.table-head-text {
    transform: translateX(-100%) !important;
}