.tungdeptrai-col{
    background-color: "#4DD4E2" !important;
    height: 100%;
}

/* TEST */

.tung-wrap {
    word-wrap: break-word;
}

@media print {

    .tung-hehe {
        width: 100% !important;
        max-height: 700px;
        min-height: 500px !important;
    }

    .break-before {
        page-break-before: always;
    }

    .break-after {
        page-break-after: always;
    }
    .assessment-patient {
        page-break-after: always;
    }
    .assessment-session-a {
        break-inside: avoid;
    }
    .assessment-session-b {
        break-inside: avoid;
    }
}