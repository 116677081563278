.__upload_file_modal .ant-modal-title {
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #22313F;
}
.__upload_file_modal .ant-modal-header, .__upload_file_modal .ant-modal-footer {
    border-bottom: none !important;
    border-top: none !important;
}
.__upload_file_modal .ant-modal-footer {
    text-align: center;
    padding-bottom: 33px;
}
.__upload_file_modal .ant-modal-footer button {
    padding: 20px 32px;
    width: 194px;
    height: 64px;
    background: #55BED2;
    border: 1px solid #55BED2;
    box-sizing: border-box;
    border-radius: 24px;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}
